const ClientData = [
    {
        id: 1,
        cover: require("../../Assets/PresClients/c1.jpg")
    },
    {
        id: 2,
        cover: require("../../Assets/PresClients/c2.jpg")
    },
    {
        id: 3,
        cover: require("../../Assets/PresClients/c3.jpg")
    },
    {
        id: 4,
        cover: require("../../Assets/PresClients/c4.jpg")
    },
    {
        id: 5,
        cover: require("../../Assets/PresClients/c5.jpg")
    },
    {
        id: 6,
        cover: require("../../Assets/PresClients/c6.jpg")
    },
    {
        id: 7,
        cover: require("../../Assets/PresClients/c7.jpg")
    },
    {
        id: 8,
        cover: require("../../Assets/PresClients/c8.jpg")
    },
    {
        id: 9,
        cover: require("../../Assets/PresClients/c9.jpg")
    },
    {
        id: 10,
        cover: require("../../Assets/PresClients/c10.jpg")
    },
    {
        id: 11,
        cover: require("../../Assets/PresClients/c11.jpg")
    },
    {
        id: 12,
        cover: require("../../Assets/PresClients/c12.jpg")
    },
    {
        id: 13,
        cover: require("../../Assets/PresClients/c13.jpg")
    },
    {
        id: 14,
        cover: require("../../Assets/PresClients/c14.jpg")
    },
    {
        id: 15,
        cover: require("../../Assets/PresClients/c15.jpg")
    },
    {
        id: 16,
        cover: require("../../Assets/PresClients/c16.jpg")
    },
    {
        id: 17,
        cover: require("../../Assets/PresClients/c17.jpg")
    },
    {
        id: 18,
        cover: require("../../Assets/PresClients/c18.jpg")
    },
    {
        id: 19,
        cover: require("../../Assets/PresClients/c19.jpg")
    },
    {
        id: 20,
        cover: require("../../Assets/PresClients/c20.jpg")
    },
    {
        id: 21,
        cover: require("../../Assets/PresClients/c21.jpg")
    },
    {
        id: 22,
        cover: require("../../Assets/PresClients/c22.jpg")
    },
    {
        id: 23,
        cover: require("../../Assets/PresClients/c23.jpg")
    },
    {
        id: 24,
        cover: require("../../Assets/PresClients/c24.jpg")
    },
    {
        id: 25,
        cover: require("../../Assets/PresClients/c25.jpg")
    },
    {
        id: 26,
        cover: require("../../Assets/PresClients/c26.jpg")
    },
    {
        id: 27,
        cover: require("../../Assets/PresClients/c27.jpg")
    },
    {
        id: 28,
        cover: require("../../Assets/PresClients/c28.jpg")
    },
    {
        id: 29,
        cover: require("../../Assets/PresClients/c29.jpg")
    },
    {
        id: 30,
        cover: require("../../Assets/PresClients/c30.jpg")
    },
    {
        id: 31,
        cover: require("../../Assets/PresClients/c31.jpg")
    },
    {
        id: 32,
        cover: require("../../Assets/PresClients/c32.jpg")
    },
    {
        id: 33,
        cover: require("../../Assets/PresClients/c33.jpg")
    },
    {
        id: 34,
        cover: require("../../Assets/PresClients/c34.jpg")
    },
    {
        id: 35,
        cover: require("../../Assets/PresClients/c35.jpg")
    },
    {
        id: 36,
        cover: require("../../Assets/PresClients/c36.jpg")
    },
    {
        id: 37,
        cover: require("../../Assets/PresClients/c37.jpg")
    },
    {
        id: 38,
        cover: require("../../Assets/PresClients/c38.jpg")
    },
    {
        id: 39,
        cover: require("../../Assets/PresClients/c39.jpg")
    },
    {
        id: 40,
        cover: require("../../Assets/PresClients/c40.jpg")
    },
    {
        id: 41,
        cover: require("../../Assets/PresClients/c41.jpg")
    },
]

export default ClientData;