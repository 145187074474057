const IndustryData = [
    { name: 'Advertisements & Promotion' },
    { name: 'Marketing / HR' },
    { name: 'Legal' },
    { name: 'General Business' },
    { name: 'Social Sciences' },
    { name: 'Medical & Pharmaceutical' },
    { name: 'Economics & Finance' },
    { name: 'Automobile' },
    { name: 'Industry & Technology' }
];

export default IndustryData;